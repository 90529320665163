/* .item {
  --background: white;
} */
ion-item {
  --ion-item-background: none;
}
ion-label {
  color: black;
}
ion-text-area {
  color: black;
}

app-button {
  background-color: #ff9800 !important;
}

.inputActive {
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 3.25px 6.51px rgba(0, 0, 0, 0.05) !important;
  border-radius: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
