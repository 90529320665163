* {
  font-family: "Montserrat";
}

.boutton-segment {
  border-radius: 30px;
  width: 97px;
  height: 37px;
  --color: #000000;
  --color-checked: none !important;
  text-transform: none;
}

.cstm-btn {
  background-color: #e82828;
  border: 2px solid #e82828;
  outline: none;
  font-weight: bold;
  --color: #ffffff !important;
  --color-checked: white !important;
  --color-hover: white;
  --indicator-color: none;
}

.boutton-segment-stock {
  --color: #000000;
  --color-checked: none !important;
  text-transform: none;
}


.cstm-btn-stock {
  background-color: #ffffff;
  --color: #e82828 !important;
  --color-checked: white !important;
  --color-hover: white;
  --indicator-color: none;
}

.boutton-date-picker {
  margin-left: 0px;
  padding-left: -40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  width: 99%;
}

.boutton-export-csv {
  font-family: "Montserrat";
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #e82828;
}


.icon-date-picker {
  width: 20px;
  height: 20px;
}

.date-paragraph {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.titre-tableau,
.total-tableau,
.liste-tableau {
  font-family: "Monserrat";
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
}

.titre-tableau,
.valeur-montant {
  font-weight: 600;
}

.titre-tableau,
.total-tableau,
.total-bilan {
  font-size: 16px;
}

.total-tableau {
  font-weight: bold;
  margin-left: -70px;
}

.total-bilan {
  font-weight: bold;
  margin-left: -20px;
}

/* ::::::::::::::::::::::::::::::::::: TABLEAU DÉPENSE ::::::::::::::::::::::::::::::::: */

.boutton-Ajouter-depense {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  background: #e82828;
  border: 1px solid #e82828;
  box-sizing: border-box;
  border-radius: 5px;
}

.boutton-valider,
.boutton-annuler {
  width: 155px;
  height: 50px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.boutton-annuler {
  background: #666666;
}

.boutton-valider {
  background: #e82828;
}

.boutton-date-picker-modal {
  margin-left: 0px;
  padding-left: -40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
}