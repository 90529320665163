* {
    font-family: Montserrat;
}

.profile-input {
    width: 100%;
    height: 40px;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.entreprise-input {
    width: 100%;
    height: 40px;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 10px;
}

.boutton-modifier {
    width: 200px;
    height: 40px;
    background: #E82828;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin: 20px 0;
}

.boutton-modifier-password,
.boutton-modifier-password2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #318CE7;
}

.boutton-modifier-password {
    margin: 20px 0;
}

.boutton-modifier-password2 {
    margin-top: 15px;
}

.boutton-segment-param {
    width: 100px;
    height: 17px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
    background: #F8F8F8;
    margin: 25px 3px;
    padding: 5px 0;
    text-transform: capitalize;
    justify-content: center;

}

.boutton-segment-param:focus {
    width: 100px;
    height: 17px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    --color-checked: #E82828;
    color: white;
    --background: #E82828;
}

.boutton-ajout-user {
    width: 240px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E82828;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #E82828;
    padding: 12px;
}

.val {
    background-color: rgba(54, 52, 52, 0.466);
    border-radius: 25px;
}

.val1 {
    background-color: rgba(54, 52, 52, 0.466);
    border-radius: 25px;
}

.val2 {
    background-color: rgba(54, 52, 52, 0.466);
    border-radius: 25px;
}

.tableau-user-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: #000000;
    margin: 0 -2px;
}

.tableau-user-content {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0 2px;
}

.tableau-user-content-list {
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-top: none;
    box-sizing: border-box;
    border-radius: 0 0 5px 5px;
}

.entete-tableau {
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 5px 5px 0 0;
}


.icone-close {
    position: absolute;
    top: 25px;
    right: 10px;
    width: 20px;
    height: 20px;
    margin-top: -10px;

}

.add-user-modal-input {
    width: 100%;
    height: 40px;
    border: 0.939815px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 2.81944px;
}

.boutton-sauvegarder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    width: 132px;
    height: 40px;
    background: #E82828;
    border: 1px solid #E82828;
    box-sizing: border-box;
    border-radius: 5px;
    color: #FFF;
    text-transform: capitalize;
}

.label-input-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #000000;
}