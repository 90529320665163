* {
    font-family: 'Montserrat';
}

.main-page {
    min-height: 100vh;
}

.ion-label {
    display: flex;
    justify-content: space-around;
}

.detail-commande {
    border: 1px solid rgba(142, 142, 142, 0.315);
    border-radius: 20px;
    padding: 5px 10px 5px 5px;
}

.detail-title {
    margin-top: 30px;
}

.button-retour {
    margin: -8px 0;
}

.carte-detail-commande {
    box-shadow: none;
    background-color: rgb(248, 248, 248);
    padding-top: 30px;
}

.titre-carte-detail {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.facture-btn,
.archive-btn {
    width: 130px;
    height: 45px;
}

.text-valeur,
.text-description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.text-valeur {
    padding-right: 30px;
}

.text-valeur-total,
.text-valeur-restant {
    font-weight: 500;
}




@media (min-width: 577px) {
    .detailCommandeGrid {
        width: 100%;
    }

    .tissuCommande {
        flex-wrap: wrap;
        display: flex;
    }
}

@media (max-width: 576px) {
    .detailCommandeGrid {
        flex-wrap: wrap;
        flex-direction: column;
        display: flex;
        margin: 0 20 20 0;


    }

    .infoMesureGrid {
        width: 100%;
        flex-direction: column;
        display: flex;
        margin: 0 20 20 0;

    }

    .tissuCommande {
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 20 20 0;

    }

    .ImageModele {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        justify-content: center;
        align-items: center;
        margin: 0 20 20 0;


    }

    .ImageModeleGrid {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 20 20 0;

    }

    .paiementContent {
        align-items: center;
        margin: 0 20 20 0;

    }

    /*Css client detail page*/
    .detailCommandeGrid {
        display: flex;
        width: 100%;
        margin: 0 20 20 0;
    }

    .infoClientGrid {
        width: 100%;
        flex-direction: column;
        display: flex;
        margin: 0 20 20 0;

    }

    .contactClient {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .clientRowItem {
        display: flow-root;
    }

    #gridInfo {
        display: column;
        justify-content: center;
        margin: 0 20 20 0;

    }

    .clientItemMesure {
        display: flow-root;

    }
}