@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  font-family: "Montserrat", sans-serif !important;
}

tr {
  height: 70px !important;
  vertical-align: middle;
}

a {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

.sidebar-item:hover {
  background-color: #fff;
}

.sidebar-item:hover ion-label {
  color: #00627c !important;
}

.nav {
  padding: 20px;
  display: inline-block;
  position: relative;
}

.nav a {
  color: #fff;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 10px;
  z-index: 100;
  position: relative;
}

.nav .active {
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 10%;
  right: 10%;
  background: #072e38;
  z-index: 0;
  -webkit-transform: rotate(-8deg);
  /* WebKit */
  /* Mozilla */
  /* Opera */
  /* Internet Explorer */
  transform: rotate(-8deg);
  /* CSS3 */
  opacity: 0.7;
  border-radius: 10px;
}

.loginLeft {
  background-image: url(/static/media/bglogin.412ccd6c.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .loginLeft {
    min-height: 5vh;
  }
}

.loginLeftContain {
  width: 300px;
}

@media screen and (min-width: 400px) {
  .loginLeftContain {
    width: 400px;
  }
}

@media screen and (min-width: 768px) {
  .loginLeftContain {
    width: 350px;
  }
}

@media screen and (min-width: 1024px) {
  .loginLeftContain {
    width: 442px;
  }
}

/* tables */

th.th {
  font-size: 16px;
  line-height: 19.5px;
  font-weight: 600;
}

td.td,
.tableCaption {
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
}

/* icons */
.ionIcon {
  pointer-events: none !important;
  opacity: 0.6;
}

.iconpointer {
  pointer-events: none !important;
}

.ionIcon:hover {
  opacity: 1;
}

.myclass {
  max-width: 324px;
  max-height: 529.39px;
}

.imgModale {
  object-fit: contain;
}

.tableLink {
  position: relative;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  display: none;
  margin-top: 2;
}

.cta-icon:hover+.tableLink {
  display: block;
  position: absolute;
  bottom: -20px;
}

.modale {
  width: 500px;
  position: absolute;
  top: 100px;
  height: 600px;
  left: 600px;
}

/* @media screen and(max-width: 425px) {
	.modale {
		left: 100px !important;
		top: 100px;
	}
} */

@media screen and(min-width: 425px) {
  .modale {
    width: 500px;
    left: 300px !important;
    top: 100px;
  }
}

/* SideBar */

.bar {
  border-top: 2px solid #c4c4c4;
  padding-top: 30px;
}

h4.h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.5px;
  color: #666666;
  padding: 0;
}

/* ::::::::::::::::::::::::::::::: APPNAV ::::::::::::::::::::::::::::::::: */

Link.Link {
  text-decoration: none;
}

.menu-nav {
  color: rgba(0, 0, 0, 0.5);
  height: 50px;
  border: none;
  margin: 20px 0;
  padding: 0 0 0 20px;
}

/* .menu-nav:hover {
  color: rgba(0, 0, 0, 0.5);
} */

.menu-nav:hover {
  color: #e82828 !important;
}

.lien-detail-commande:hover {
  text-decoration: none;
}

.img-logo {
  margin: auto;
}

.sidebar {
  border: none;
}

.IonSearchbar {
  border: none;
  box-shadow: 1px 1px 15px rgba(128, 128, 128, 0.185);
  width: 60%;
}

.en-tete {
  background-color: rgb(248, 248, 248);
}

.menu-tableau {
  margin-top: 75px;
}

.label {
  font-size: 16px;
  line-height: 19.5px;
  font-weight: 600;
  opacity: 0.7;
  text-decoration: none;
}

.buttoni {
  background-color: #e82828;
  border: 2px solid #e82828;
  color: #fff;
  outline: none;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.buttoni:hover {
  background-color: #fff;
  border: 2px solid #e82828;
  color: #e82828;
  outline: none;
}

/* Modeles */
.tabs {
  opacity: 0.5;
}

.tabs:hover {
  border-bottom: 1px solid #e82828;
  opacity: 1;
}

.tabs:focus {
  color: #e82828 !important;
  opacity: 1;
}

.div-parent {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

/* .active {
  color: #e82828 !important;
  border-right: 3px solid #e82828;
  opacity: 1;
  text-decoration: none;
} */

ion-item.my-item:hover {
  --color: red;
}

ion-icon.my-icon {
  stroke: black;
  stroke-opacity: 0.5;
}

ion-icon.modalHomeIcon {
  stroke: white;
}

ion-icon.modalUserIcon {
  stroke: white;

}


ion-icon.my-icon:hover {
  fill: red;
  stroke: #e82828;
  stroke-opacity: unset;
}

.activated ion-icon.my-icon {
  fill: red;
  stroke: #e82828;
  stroke-opacity: unset;
}

/* Setting the active link */
.activated {
  border-right: 5px solid #e82828;
}

.activated ion-item.my-item {
  color: #e82828;
}

/* ion-item:hover {
  color: #e82828 !important;
  --color-hover: red;
} */

/* Making the row to scroll inside parent */
.myContent {
  overflow: auto;
  height: 60vh;
}

.myContent::-webkit-scrollbar {
  display: none;
}

.labelModal {
  font-weight: 500;
  font-size: 16px;
}

.input-error-feedback {
  background: #dc3545 !important;
  padding: 5px;
  color: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
}
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #E72D32;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #fff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

/* .item {
  --background: white;
} */
ion-item {
  --ion-item-background: none;
}
ion-label {
  color: black;
}
ion-text-area {
  color: black;
}

app-button {
  background-color: #ff9800 !important;
}

.inputActive {
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 3.25px 6.51px rgba(0, 0, 0, 0.05) !important;
  border-radius: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ion-input.sc-ion-input-md-h.sc-ion-input-md-s.md.hydrate{
   display: none;
}
.label-input {
   display: block;
   position: relative;
   background-color: #E82828;
   color: #ffffff;
   font-size: 18px;
   text-align: center;
   width: 145px;
   padding: 18px 0;
   margin: auto;
   border-radius: 5px;
   cursor: pointer;
}
.container p{
   text-align: center;
   margin: 20px 0 30px 0;
}
#images{
   width: 90%;
   position: relative;
   margin: auto;
   display: flex;
   justify-content: space-evenly;
   grid-gap: 20px;
   gap: 20px;
   flex-wrap: wrap;
}
figure{
   width: 45%;
}
img{
   width: 100%;
}
figcaption{
   text-align: center;
   font-size: 2.4vmin;
   margin-top: 0.5vmin;
}

section {
   padding: 2rem 0;
 }
 
 .label-input {
   display: block;
   position: relative;
   background-color: #E82828;
   color: #ffffff;
   font-size: 15px;
   font-weight: 500;
   text-align: center;
   width: 165px;
   height: 50px;
   padding: 20px 0;
   margin: auto;
   border-radius: 5px;
   cursor: pointer;
 }
 
 label span {
   font-weight: lighter;
   font-size: small;
   padding-top: 0.5rem;
 }
 
 input {
   display: none;
 }
 
 img {
   padding: 0;
   margin: 0;
 }
 
 .images {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   flex-grow: inherit;
   align-items: center;
 }
 
 .image {
   margin: 1rem 0.5rem;
   position: relative;
   box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
 }
 
 .image button {
   position: absolute;
   bottom: 0;
   right: 0;
   cursor: pointer;
   border: none;
   color: white;
   background-color: #E82828;
 }
 
 .image button:hover {
   background-color: #E82828;
 }
 
 .image p {
   padding: 0 0.5rem;
   margin: 0;
 }
 
 .upload-btn {
   cursor: pointer;
   display: block;
   margin: 0 auto;
   border: none;
   border-radius: 20px;
   width: 10rem;
   height: 2rem;
   color: white;
   background-color: #E82828;
 }
 
 label:hover,
 .upload-btn:hover {
   box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
     rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
 }
 
 .error {
   text-align: center;
 }
 
 .error span {
   color: #E82828;
 }
* {
    font-family: 'Montserrat';
}

.main-page {
    min-height: 100vh;
}

.ion-label {
    display: flex;
    justify-content: space-around;
}

.detail-commande {
    border: 1px solid rgba(142, 142, 142, 0.315);
    border-radius: 20px;
    padding: 5px 10px 5px 5px;
}

.detail-title {
    margin-top: 30px;
}

.button-retour {
    margin: -8px 0;
}

.carte-detail-commande {
    box-shadow: none;
    background-color: rgb(248, 248, 248);
    padding-top: 30px;
}

.titre-carte-detail {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.facture-btn,
.archive-btn {
    width: 130px;
    height: 45px;
}

.text-valeur,
.text-description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.text-valeur {
    padding-right: 30px;
}

.text-valeur-total,
.text-valeur-restant {
    font-weight: 500;
}




@media (min-width: 577px) {
    .detailCommandeGrid {
        width: 100%;
    }

    .tissuCommande {
        flex-wrap: wrap;
        display: flex;
    }
}

@media (max-width: 576px) {
    .detailCommandeGrid {
        flex-wrap: wrap;
        flex-direction: column;
        display: flex;
        margin: 0 20 20 0;


    }

    .infoMesureGrid {
        width: 100%;
        flex-direction: column;
        display: flex;
        margin: 0 20 20 0;

    }

    .tissuCommande {
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 20 20 0;

    }

    .ImageModele {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        justify-content: center;
        align-items: center;
        margin: 0 20 20 0;


    }

    .ImageModeleGrid {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 20 20 0;

    }

    .paiementContent {
        align-items: center;
        margin: 0 20 20 0;

    }

    /*Css client detail page*/
    .detailCommandeGrid {
        display: flex;
        width: 100%;
        margin: 0 20 20 0;
    }

    .infoClientGrid {
        width: 100%;
        flex-direction: column;
        display: flex;
        margin: 0 20 20 0;

    }

    .contactClient {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .clientRowItem {
        display: flow-root;
    }

    #gridInfo {
        display: column;
        justify-content: center;
        margin: 0 20 20 0;

    }

    .clientItemMesure {
        display: flow-root;

    }
}
* {
  font-family: "Montserrat";
}

.boutton-segment {
  border-radius: 30px;
  width: 97px;
  height: 37px;
  --color: #000000;
  --color-checked: none !important;
  text-transform: none;
}

.cstm-btn {
  background-color: #e82828;
  border: 2px solid #e82828;
  outline: none;
  font-weight: bold;
  --color: #ffffff !important;
  --color-checked: white !important;
  --color-hover: white;
  --indicator-color: none;
}

.boutton-segment-stock {
  --color: #000000;
  --color-checked: none !important;
  text-transform: none;
}


.cstm-btn-stock {
  background-color: #ffffff;
  --color: #e82828 !important;
  --color-checked: white !important;
  --color-hover: white;
  --indicator-color: none;
}

.boutton-date-picker {
  margin-left: 0px;
  padding-left: -40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  width: 99%;
}

.boutton-export-csv {
  font-family: "Montserrat";
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #e82828;
}


.icon-date-picker {
  width: 20px;
  height: 20px;
}

.date-paragraph {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.titre-tableau,
.total-tableau,
.liste-tableau {
  font-family: "Monserrat";
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
}

.titre-tableau,
.valeur-montant {
  font-weight: 600;
}

.titre-tableau,
.total-tableau,
.total-bilan {
  font-size: 16px;
}

.total-tableau {
  font-weight: bold;
  margin-left: -70px;
}

.total-bilan {
  font-weight: bold;
  margin-left: -20px;
}

/* ::::::::::::::::::::::::::::::::::: TABLEAU DÉPENSE ::::::::::::::::::::::::::::::::: */

.boutton-Ajouter-depense {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  background: #e82828;
  border: 1px solid #e82828;
  box-sizing: border-box;
  border-radius: 5px;
}

.boutton-valider,
.boutton-annuler {
  width: 155px;
  height: 50px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.boutton-annuler {
  background: #666666;
}

.boutton-valider {
  background: #e82828;
}

.boutton-date-picker-modal {
  margin-left: 0px;
  padding-left: -40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
}
.planing-colonne {
  border: 1px solid rgba(128, 128, 128, 0.075);
  box-shadow: 1px 1px 10px rgba(128, 128, 128, 0.055);
  width: 99%;
}

.contenu-de-carte {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}
.contenu-de-carte:hover {
  color: white;
  background: rgb(217, 69, 61);
  cursor: pointer;
}

.icone-card {
  width: 58px !important;
  height: 43px !important;
}
.title-card {
  font-size: 1.5rem;
  margin-top: 10px;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  text-align: right;
  color: #383639;
}

.frc-cfa {
  font-size: 1.2rem;
  color: grey;
  font-weight: normal;
}

.cartes-dashboard {
  height: 174px;
  padding: 20px;
  border: 0.530882px solid rgba(218, 218, 218, 0.3);
  box-shadow: 1px 1px 15px rgba(128, 128, 128, 0.185);
  border-radius: 10px;
}

.card-planning {
  box-shadow: 1px 1px 15px rgba(128, 128, 128, 0.185);
}

.nom-carte {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.date-carte {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  padding: 0 16px 16px 13px;
}

.main-page {
  background-color: rgb(248, 248, 248);
  padding-top: 30px;
}

.page-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.data {
  border-bottom: 2px solid rgba(196, 196, 196, 0.2);
}

.titre-carte {
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  font-size: 14.86px;
  line-height: 17px;
  text-align: right;

  color: #383639;
}

.planning-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.titre-cette-semaine {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.boutton-voie-plus {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #db4437;
}

.lien-detail-commande {
  color: #000000;
}

.lien-detail-commande:hover {
  text-decoration: none;
  color: #fff;
}

/* My overlay style */
.my-custom-class {
  color: #fff;
}

@media (min-width: 381px) {
  /* .nom-carte {
        font-weight: 400;
        line-height: 10px;
    }
    .date-carte {
        font-weight: 300;
        line-height: 5px;
    } */
}

.imageShow {
    cursor: pointer;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
}
* {
    font-family: Montserrat;
}

.profile-input {
    width: 100%;
    height: 40px;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.entreprise-input {
    width: 100%;
    height: 40px;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 10px;
}

.boutton-modifier {
    width: 200px;
    height: 40px;
    background: #E82828;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin: 20px 0;
}

.boutton-modifier-password,
.boutton-modifier-password2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #318CE7;
}

.boutton-modifier-password {
    margin: 20px 0;
}

.boutton-modifier-password2 {
    margin-top: 15px;
}

.boutton-segment-param {
    width: 100px;
    height: 17px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
    background: #F8F8F8;
    margin: 25px 3px;
    padding: 5px 0;
    text-transform: capitalize;
    justify-content: center;

}

.boutton-segment-param:focus {
    width: 100px;
    height: 17px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    --color-checked: #E82828;
    color: white;
    --background: #E82828;
}

.boutton-ajout-user {
    width: 240px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E82828;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #E82828;
    padding: 12px;
}

.val {
    background-color: rgba(54, 52, 52, 0.466);
    border-radius: 25px;
}

.val1 {
    background-color: rgba(54, 52, 52, 0.466);
    border-radius: 25px;
}

.val2 {
    background-color: rgba(54, 52, 52, 0.466);
    border-radius: 25px;
}

.tableau-user-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: #000000;
    margin: 0 -2px;
}

.tableau-user-content {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0 2px;
}

.tableau-user-content-list {
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-top: none;
    box-sizing: border-box;
    border-radius: 0 0 5px 5px;
}

.entete-tableau {
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 5px 5px 0 0;
}


.icone-close {
    position: absolute;
    top: 25px;
    right: 10px;
    width: 20px;
    height: 20px;
    margin-top: -10px;

}

.add-user-modal-input {
    width: 100%;
    height: 40px;
    border: 0.939815px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 2.81944px;
}

.boutton-sauvegarder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    width: 132px;
    height: 40px;
    background: #E82828;
    border: 1px solid #E82828;
    box-sizing: border-box;
    border-radius: 5px;
    color: #FFF;
    text-transform: capitalize;
}

.label-input-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #000000;
}
.bouttonStatut {
   border: 2px solid #f2f2f2;
   color: #000;
   border-radius: 10;
   background-color: white;
}
.bouttonStatut:hover {
   background-color: #E82828;
   border:none
}
@media screen and (min-width: 768px) {
   .inputQuantite {
      flex-wrap: wrap;
   }
   .QuatiteInput {
      margin-top: 10;
   }

 }


 @media (min-width: 381px) {
   .inputQuantite {
      flex-wrap: wrap;
   }
   .QuatiteInput {
      margin-top: 10;
   }
 } 
 
ion-input.sc-ion-input-md-h.sc-ion-input-md-s.md.hydrate{
   display: none;
}
.label-input {
   display: block;
   position: relative;
   background-color: #E82828;
   color: #ffffff;
   font-size: 18px;
   text-align: center;
   width: 145px;
   padding: 18px 0;
   margin: auto;
   border-radius: 5px;
   cursor: pointer;
}
.container p{
   text-align: center;
   margin: 20px 0 30px 0;
}
#images{
   width: 90%;
   position: relative;
   margin: auto;
   display: flex;
   justify-content: space-evenly;
   grid-gap: 20px;
   gap: 20px;
   flex-wrap: wrap;
}
figure{
   width: 45%;
}
img{
   width: 100%;
}
figcaption{
   text-align: center;
   font-size: 2.4vmin;
   margin-top: 0.5vmin;
}

section {
   padding: 2rem 0;
 }
 
 .label-input {
   display: block;
   position: relative;
   background-color: #E82828;
   color: #ffffff;
   font-size: 15px;
   font-weight: 500;
   text-align: center;
   width: 165px;
   height: 50px;
   padding: 20px 0;
   margin: auto;
   border-radius: 5px;
   cursor: pointer;
 }
 
 label span {
   font-weight: lighter;
   font-size: small;
   padding-top: 0.5rem;
 }
 
 input {
   display: none;
 }
 
 img {
   padding: 0;
   margin: 0;
 }
 
 .images {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   flex-grow: inherit;
   align-items: center;
 }
 
 .image {
   margin: 1rem 0.5rem;
   position: relative;
   box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
 }
 
 .image button {
   position: absolute;
   bottom: 0;
   right: 0;
   cursor: pointer;
   border: none;
   color: white;
   background-color: #E82828;
 }
 
 .image button:hover {
   background-color: #E82828;
 }
 
 .image p {
   padding: 0 0.5rem;
   margin: 0;
 }
 
 .upload-btn {
   cursor: pointer;
   display: block;
   margin: 0 auto;
   border: none;
   border-radius: 20px;
   width: 10rem;
   height: 2rem;
   color: white;
   background-color: #E82828;
 }
 
 label:hover,
 .upload-btn:hover {
   box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
     rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
 }
 
 .error {
   text-align: center;
 }
 
 .error span {
   color: #E82828;
 }

ion-row.hydrated.ion-align-items-center {
  align-items: center;
  display: flex;
  justify-content: center;
}
