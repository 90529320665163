.planing-colonne {
  border: 1px solid rgba(128, 128, 128, 0.075);
  box-shadow: 1px 1px 10px rgba(128, 128, 128, 0.055);
  width: 99%;
}

.contenu-de-carte {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}
.contenu-de-carte:hover {
  color: white;
  background: rgb(217, 69, 61);
  cursor: pointer;
}

.icone-card {
  width: 58px !important;
  height: 43px !important;
}
.title-card {
  font-size: 1.5rem;
  margin-top: 10px;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  text-align: right;
  color: #383639;
}

.frc-cfa {
  font-size: 1.2rem;
  color: grey;
  font-weight: normal;
}

.cartes-dashboard {
  height: 174px;
  padding: 20px;
  border: 0.530882px solid rgba(218, 218, 218, 0.3);
  box-shadow: 1px 1px 15px rgba(128, 128, 128, 0.185);
  border-radius: 10px;
}

.card-planning {
  box-shadow: 1px 1px 15px rgba(128, 128, 128, 0.185);
}

.nom-carte {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.date-carte {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  padding: 0 16px 16px 13px;
}

.main-page {
  background-color: rgb(248, 248, 248);
  padding-top: 30px;
}

.page-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.data {
  border-bottom: 2px solid rgba(196, 196, 196, 0.2);
}

.titre-carte {
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  font-size: 14.86px;
  line-height: 17px;
  text-align: right;

  color: #383639;
}

.planning-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.titre-cette-semaine {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.boutton-voie-plus {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #db4437;
}

.lien-detail-commande {
  color: #000000;
}

.lien-detail-commande:hover {
  text-decoration: none;
  color: #fff;
}

/* My overlay style */
.my-custom-class {
  color: #fff;
}

@media (min-width: 381px) {
  /* .nom-carte {
        font-weight: 400;
        line-height: 10px;
    }
    .date-carte {
        font-weight: 300;
        line-height: 5px;
    } */
}
