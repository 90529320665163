.bouttonStatut {
   border: 2px solid #f2f2f2;
   color: #000;
   border-radius: 10;
   background-color: white;
}
.bouttonStatut:hover {
   background-color: #E82828;
   border:none
}
@media screen and (min-width: 768px) {
   .inputQuantite {
      flex-wrap: wrap;
   }
   .QuatiteInput {
      margin-top: 10;
   }

 }


 @media (min-width: 381px) {
   .inputQuantite {
      flex-wrap: wrap;
   }
   .QuatiteInput {
      margin-top: 10;
   }
 } 
 