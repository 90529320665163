ion-input.sc-ion-input-md-h.sc-ion-input-md-s.md.hydrate{
   display: none;
}
.label-input {
   display: block;
   position: relative;
   background-color: #E82828;
   color: #ffffff;
   font-size: 18px;
   text-align: center;
   width: 145px;
   padding: 18px 0;
   margin: auto;
   border-radius: 5px;
   cursor: pointer;
}
.container p{
   text-align: center;
   margin: 20px 0 30px 0;
}
#images{
   width: 90%;
   position: relative;
   margin: auto;
   display: flex;
   justify-content: space-evenly;
   gap: 20px;
   flex-wrap: wrap;
}
figure{
   width: 45%;
}
img{
   width: 100%;
}
figcaption{
   text-align: center;
   font-size: 2.4vmin;
   margin-top: 0.5vmin;
}

section {
   padding: 2rem 0;
 }
 
 .label-input {
   display: block;
   position: relative;
   background-color: #E82828;
   color: #ffffff;
   font-size: 15px;
   font-weight: 500;
   text-align: center;
   width: 165px;
   height: 50px;
   padding: 20px 0;
   margin: auto;
   border-radius: 5px;
   cursor: pointer;
 }
 
 label span {
   font-weight: lighter;
   font-size: small;
   padding-top: 0.5rem;
 }
 
 input {
   display: none;
 }
 
 img {
   padding: 0;
   margin: 0;
 }
 
 .images {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   flex-grow: inherit;
   align-items: center;
 }
 
 .image {
   margin: 1rem 0.5rem;
   position: relative;
   box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
 }
 
 .image button {
   position: absolute;
   bottom: 0;
   right: 0;
   cursor: pointer;
   border: none;
   color: white;
   background-color: #E82828;
 }
 
 .image button:hover {
   background-color: #E82828;
 }
 
 .image p {
   padding: 0 0.5rem;
   margin: 0;
 }
 
 .upload-btn {
   cursor: pointer;
   display: block;
   margin: 0 auto;
   border: none;
   border-radius: 20px;
   width: 10rem;
   height: 2rem;
   color: white;
   background-color: #E82828;
 }
 
 label:hover,
 .upload-btn:hover {
   box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
     rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
 }
 
 .error {
   text-align: center;
 }
 
 .error span {
   color: #E82828;
 }

ion-row.hydrated.ion-align-items-center {
  align-items: center;
  display: flex;
  justify-content: center;
}