@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Montserrat", sans-serif !important;
}

tr {
  height: 70px !important;
  vertical-align: middle;
}

a {
  text-decoration-line: none;
}

.sidebar-item:hover {
  background-color: #fff;
}

.sidebar-item:hover ion-label {
  color: #00627c !important;
}

.nav {
  padding: 20px;
  display: inline-block;
  position: relative;
}

.nav a {
  color: #fff;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 10px;
  z-index: 100;
  position: relative;
}

.nav .active {
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 10%;
  right: 10%;
  background: #072e38;
  z-index: 0;
  -webkit-transform: rotate(-8deg);
  /* WebKit */
  -moz-transform: rotate(-8deg);
  /* Mozilla */
  -o-transform: rotate(-8deg);
  /* Opera */
  -ms-transform: rotate(-8deg);
  /* Internet Explorer */
  transform: rotate(-8deg);
  /* CSS3 */
  opacity: 0.7;
  border-radius: 10px;
}

.loginLeft {
  background-image: url("./images/bglogin.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .loginLeft {
    min-height: 5vh;
  }
}

.loginLeftContain {
  width: 300px;
}

@media screen and (min-width: 400px) {
  .loginLeftContain {
    width: 400px;
  }
}

@media screen and (min-width: 768px) {
  .loginLeftContain {
    width: 350px;
  }
}

@media screen and (min-width: 1024px) {
  .loginLeftContain {
    width: 442px;
  }
}

/* tables */

th.th {
  font-size: 16px;
  line-height: 19.5px;
  font-weight: 600;
}

td.td,
.tableCaption {
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
}

/* icons */
.ionIcon {
  pointer-events: none !important;
  opacity: 0.6;
}

.iconpointer {
  pointer-events: none !important;
}

.ionIcon:hover {
  opacity: 1;
}

.myclass {
  max-width: 324px;
  max-height: 529.39px;
}

.imgModale {
  object-fit: contain;
}

.tableLink {
  position: relative;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  display: none;
  margin-top: 2;
}

.cta-icon:hover+.tableLink {
  display: block;
  position: absolute;
  bottom: -20px;
}

.modale {
  width: 500px;
  position: absolute;
  top: 100px;
  height: 600px;
  left: 600px;
}

/* @media screen and(max-width: 425px) {
	.modale {
		left: 100px !important;
		top: 100px;
	}
} */

@media screen and(min-width: 425px) {
  .modale {
    width: 500px;
    left: 300px !important;
    top: 100px;
  }
}

/* SideBar */

.bar {
  border-top: 2px solid #c4c4c4;
  padding-top: 30px;
}

h4.h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.5px;
  color: #666666;
  padding: 0;
}

/* ::::::::::::::::::::::::::::::: APPNAV ::::::::::::::::::::::::::::::::: */

Link.Link {
  text-decoration: none;
}

.menu-nav {
  color: rgba(0, 0, 0, 0.5);
  height: 50px;
  border: none;
  margin: 20px 0;
  padding: 0 0 0 20px;
}

/* .menu-nav:hover {
  color: rgba(0, 0, 0, 0.5);
} */

.menu-nav:hover {
  color: #e82828 !important;
}

.lien-detail-commande:hover {
  text-decoration: none;
}

.img-logo {
  margin: auto;
}

.sidebar {
  border: none;
}

.IonSearchbar {
  border: none;
  box-shadow: 1px 1px 15px rgba(128, 128, 128, 0.185);
  width: 60%;
}

.en-tete {
  background-color: rgb(248, 248, 248);
}

.menu-tableau {
  margin-top: 75px;
}

.label {
  font-size: 16px;
  line-height: 19.5px;
  font-weight: 600;
  opacity: 0.7;
  text-decoration: none;
}

.buttoni {
  background-color: #e82828;
  border: 2px solid #e82828;
  color: #fff;
  outline: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.buttoni:hover {
  background-color: #fff;
  border: 2px solid #e82828;
  color: #e82828;
  outline: none;
}

/* Modeles */
.tabs {
  opacity: 0.5;
}

.tabs:hover {
  border-bottom: 1px solid #e82828;
  opacity: 1;
}

.tabs:focus {
  color: #e82828 !important;
  opacity: 1;
}

.div-parent {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

/* .active {
  color: #e82828 !important;
  border-right: 3px solid #e82828;
  opacity: 1;
  text-decoration: none;
} */

ion-item.my-item:hover {
  --color: red;
}

ion-icon.my-icon {
  stroke: black;
  stroke-opacity: 0.5;
}

ion-icon.modalHomeIcon {
  stroke: white;
}

ion-icon.modalUserIcon {
  stroke: white;

}


ion-icon.my-icon:hover {
  fill: red;
  stroke: #e82828;
  stroke-opacity: unset;
}

.activated ion-icon.my-icon {
  fill: red;
  stroke: #e82828;
  stroke-opacity: unset;
}

/* Setting the active link */
.activated {
  border-right: 5px solid #e82828;
}

.activated ion-item.my-item {
  color: #e82828;
}

/* ion-item:hover {
  color: #e82828 !important;
  --color-hover: red;
} */

/* Making the row to scroll inside parent */
.myContent {
  overflow: auto;
  height: 60vh;
}

.myContent::-webkit-scrollbar {
  display: none;
}

.labelModal {
  font-weight: 500;
  font-size: 16px;
}

.input-error-feedback {
  background: #dc3545 !important;
  padding: 5px;
  color: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
}